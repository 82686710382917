<template>
	<main class="page-container">
		<!-- s: 판로TV VOD -->
		<section class="broadcast-vod" data-fill="true">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">판로TV 하이라이트</h3>
				</div>
				<!-- VOD 제목 -->
				<div class="broadcast-vod-header">
					<p class="broadcast-vod-header__cate"></p>
					<div class="broadcast-vod-header__title">
						<p class="title">
							{{ brcTit }}
						</p>
						<span class="date"></span>
					</div>
				</div>
				<!-- //VOD 제목 -->

				<!-- VOD player -->
				<div class="vod-wrap">
					<iframe 
						:id="`myplayer_shorts_${brcInfoNo}`" 
						:src="playerUrl" 
						:allowFullScreen="true" 
						webkitallowfullscreen="true" 
						mozallowfullscreen="true" 
						width="600" 
						height="400"
						frameborder="0" 
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
					></iframe>
				</div>
				<!-- //VOD player -->

				<!-- VOD 도구 -->
				<div class="broadcast-vod-tools">
					<button type="button" class="button" :class="{ 'is-active': soundOn }" @click="onToggleSound">
						<i class="icon icon-vod-soundoff"></i>
					</button>
					<button type="button" class="button" :class="{ likeVod: liked }" @click="addRecommCnt">
						<i class="icon icon-vod-like"></i>
						<span class="text">좋아요</span>
						<span class="nums">{{ recommCnt }}</span>
					</button>
					<button type="button" class="button" @click="onShareUrl">
						<i class="icon icon-vod-share"></i>
						<input type="text" id="urlInput" readonly style="position: absolute; left: -10000px" />
						<span class="text">공유</span>
					</button>

					<a v-if="relLink" :href="relLink" target="_blank" title="새창 열림" class="button">
						<i class="icon icon-vod-link"></i>
						<span class="text">관련페이지</span>
					</a>
				</div>
				<!-- //VOD 도구 -->
				<!-- VOD 공지 -->
				<div class="broadcast-vod-notice" v-html="notice"></div>
				<!-- //VOD 공지 -->
			</div>
		</section>
		<!-- e: 판로TV VOD -->

		<!-- s: 하이라이트 영상 -->
		<section class="broadcast-section broadcast-section--bg">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">하이라이트 영상</h3>
					<nav class="page-nav">
						<ul class="nav-list">
							<li class="nav-item" :class="{ 'is-selected': programCd == '' || !programCd }">
								<button type="button" class="nav-text" @click="getShortsList('')">전체</button>
							</li>
							<li v-for="(program, idx) in shortsProgramListSort" :key="idx" class="nav-item"
								:class="{ 'is-selected': programCd === program.PROGRAM_CD }">
								<button type="button" class="nav-text" @click="getShortsList(program.PROGRAM_CD)">{{ program.PROGRAM_NM }}</button>
							</li>
						</ul>
					</nav>
				</div>
				<div class="broadcast-component broadcast-component-category">
					<div v-if="isEmptyShortsList(shortsList)" class="no-results-found has-icon is-large">
						<p class="text">현재 콘텐츠 준비중입니다.</p>
					</div>
					<swiper v-if="!isEmptyShortsList(shortsList)" class="swiper" ref="mySwiper" :options="swiperOptionCate">
						<swiper-slide v-for="(item, idx) in shortsList" :key="idx">
							<!--  BRC_STS_CD : 07(방송예정), 10(라이브), 20(VOD)       -->
							<broadcast-card-short-form type="20" :watch="item.INQ_CNT"
								:img="`${item.BRC_RPRS_IMG_URL}`"
								:alt="item.BRC_TIT" 
								:brcInfoNo="item.BRC_INFO_NO"
								:playerUrl="item.PLAYER_URL[0]"
								:vrtYn="item.VRT_YN"
								:title="item.TITLE" 
								:subDesc="item.BRC_TIT" 
								:relLink="item.REL_LINK"
								@clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination" slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</section>
		<!-- e: 하이라이트 영상 -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	ACT_GET_BRC_INFO,
	ACT_GET_LIVE_VIDEO_LIST,
	ACT_GET_PROG_SMSUSER_LIST,
	ACT_INSERT_RECOMM_CNT
} from '@/store/_act_consts';
import {
	MUT_SET_RETURN_ROUTE,
} from '@/store/_mut_consts';

import { getItems } from '@/assets/js/utils';
import BroadcastCardShortForm from '@/components/broadcast/BroadcastCardShortForm';

const jsonp = require('jsonp');

export default {
	name: 'ShortFormView',
	components: {
		BroadcastCardShortForm,
	},
	data: () => ({
		_controller: null,
		swiperOptionCate: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-component-category .swiper-button-next',
				prevEl: '.broadcast-component-category .swiper-button-prev',
			},
			breakpoints: {
				280: {
					slidesPerView: 1.2,
					spaceBetween: 20,
					// slidesPerView: 1,
				},
				500: {
					slidesPerView: 2.2,
					spaceBetween: 10,
					// slidesPerView: 2,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 4,
				},
			},
		},

		brcInfoNo: null,
		playerUrl: '',
		brcTit: '',
		soundOn: false, // 소리출력 옵션
		liked: false,
		likeCnt: 0,
		recommCnt: 0,

		smsList: [],
		programList: [],
		shortsList:[],
		vodList: [],
		programCd: '',

		isLoginGuide: false,
		notice: '',
		relLink: '',
		title: '',
		clientIp: '0.0.0.0',
	}),
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('broadcast', ['broadcastVod', 'previewTime', 'shortsBrcTyp']),
		shortsProgramListSort: function () {
			let list = [];
			this.programList.forEach(element => {
				if(element.PROGRAM_CD != '04' && element.PROGRAM_CD != '05'){
					list.push(element);
				}				
			});
			return list;
		},
	},
	watch: {
		session: function (newVal, oldVal) {
			this.getUserSmsList(newVal.userId);
		},
		$route(to) {
			this.getBrcInfo();
			this.brcInfoNo = to.params.brcInfoNo
		},
	},
	created() {
		this.getShortsList(this.programCd);

		if (!this.$cookies.get('gachi-brodcast-noneuser')) {
			this.$cookies.set('gachi-brodcast-noneuser', this.generateRandomString(8), '1d');
		}
	},
	mounted() {
		this.getBrcInfo();
		this.getClientIP();
		this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
	},
	beforeDestroy() {
		if (null != this._controller) {
			this._controller.dispose()
		}
	},
	methods: {
		// event ========================================================================================
		// 소리 옵션 토글
		onToggleSound() {
			this._controller.mute();
			this._controller.on('muted', (is_muted) =>{
				(is_muted) ? this.soundOn = false : this.soundOn = true
			});
		},
		//좋아요 이벤트
		addRecommCnt() {
			if (!this.liked) {
				//좋아요 버튼 누를시
				this.likeCnt = 1;
				this.liked = true;
			} else {
				//좋아요 버튼 해제시
				this.likeCnt = -1;
				this.liked = false;
			}

			this.$store
				.dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
					brcInfoNo: this.$route.params.brcInfoNo,
					recommCnt: this.likeCnt,
					clientIP: this.clientIp,
					anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
				})
				.then((res) => {
					// console.log('res : ' + JSON.stringify(res));
					this.recommCnt = res.data.VIEW.RECOMM_CNT;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		onShareUrl() {
			//url 넣을 input 생성
			const urlInput = document.getElementById('urlInput');

			//현재 URL 가져오기
			urlInput.value = window.document.location.href;

			//clipboard 복사
			try {
				urlInput.select();
				document.execCommand('copy');
				// alert(`URL 복사 : ${urlInput.value}`);
				alert('URL이 복사 되었습니다.');
			} catch (error) {
				console.error(error);
			}
		},

		// custon fn ========================================================================================
		generateRandomString(num) {
			const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
			let result = '';
			const charactersLength = characters.length;
			for (let i = 0; i < num; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}

			return result;
		},
		isEmptyShortsList(shortsList = []) {
			return shortsList.length < 1
		},
		
		initVgController() {
			try {
				this._controller = new VgControllerClient({
					target_window: document.getElementById(`myplayer_shorts_${this.brcInfoNo}`).contentWindow,
				});
		
				this._controller.on('error', (err) => {
					console.log('error: ' + err);
				});
		
				this._controller.on('ready', () => {
					this._controller.set_volume(50);
					this._controller.play();
				});

				this._controller.on('muted', (is_muted) => {
					if (is_muted) {
						this.soundOn = false
						is_muted = false
					}
					else {
						this.soundOn = true
						is_muted = true
					}
				});
		
				// this._controller.on('play', () => {
				// 	if (!isAuth) {
				// 		this._controller.set_control_visibility(false); // 내장 Controller 숨기:false, 숨김해제:true
				// 	}
				// });
		
				// this._controller.on('progress', (percent, position, videoTime) => {
				// 	//진행백분율(0~100) , 재생위치(초단위), 전체재생길이(초단위)
				// 	//console.log('position : ' + position);
				// 	// console.log('재생길이 : ' + videoTime);
				// 	if (!isAuth && position > this.previewTime) {
				// 		this._controller.play(0);
				// 		this._controller.pause();
				// 	}
				// });
		
				// this._controller.on('pause', () => {
				// 	if (!isAuth && !this.isLoginGuide) {
				// 		this.isLoginGuide = true;
				// 		alert(`비회원은 3분미리보기만 가능합니다. \n 회원가입후 이용해주세요.`);
				// 	}
				// });
			}
			catch (err) {
				console.log(err);
			}
		},


		// promise ========================================================================================
		async getClientIP() {
			jsonp('https://jsonip.com/', null, (err, data) => {
				if (err) {
					this.clientIp = '1.1.1.1';
				} else {
					this.clientIp = data.ip;
				}
			});
		},

		async getUserSmsList(userId) {
			//사용자SMS발송여부 체크박스 초기화
			this.smsList.splice(0);

			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined') {
				this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: userId,
					})
					.then((res) => {
						var items = getItems(res);
						for (var i = 0; i < items.length; i++) {
							this.smsList.push(items[i].programCd);
						}
					})
					.catch((e) => {
						console.error(e);
					});
			}
		},

		async getBrcInfo() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
					brcInfoNo: this.$route.params.brcInfoNo,
					brcTyp: this.shortsBrcTyp,
				})
				.then((res) => {
					// console.log(res);
					let shorts = res.data.VIEW;
					this.playerUrl = shorts.PLAYER_URL;
					this.notice = shorts.noties[0].NTC_CASE_CN.replaceAll(/(\n|\r\n)/g, '<br>');
					this.brcTit = shorts.BRC_TIT;

					if (shorts.links[0]) this.relLink = shorts.links[0].REL_LINK;
					if (shorts.links[0]) this.title = shorts.links[0].TITLE;

					this.programCd = shorts.PROGRAM_CD;
					this.recommCnt = shorts.RECOMM_CNT;
				})
				.then(() => this.initVgController())
				.catch((e) => {
					console.error(e);
				});
		},
		async getShortsList(programCd) {
			this.programCd = programCd == '' ? '' : programCd;

			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					pageIndex: 1,
					brcTyp: this.shortsBrcTyp,
					programCd: programCd,
					brcStsCd: '20',
				})
				.then((res) => {
					// console.log(res);
					this.shortsList = res.data.LIST;
					this.programList = res.data.PROGRAM_LIST;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	},
};
</script>
